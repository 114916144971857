import { defaultHeaders, defaultApexHeaders } from "./const/defaultHeaders";
import { version } from "../package.json";
import { post } from "./utils/poster";
import { addListener, selectTarget, getElementAxis, getContentText, getPageInfo } from "./utils/dom";
import { proxyPushState } from "./utils/history";
import { isTestDomain } from "./utils/env";

const isMigu = window.navigator.userAgent.match(
  /(mobilemusic|mgmusic|mgmedia);?/i
);

export class Apex {
  version = version;
  debug = "";
  appId = "h5";
  platform = "4";
  headers = defaultHeaders;
  apexHeaders: any = defaultApexHeaders;
  _stopListen: any = null;
  _currentPageInfo: any = {
    page: "",
    startTime: 0,
  };

  setAppId(appId: string, platform?: string) {
    this.appId = appId;
    this.headers.appId = appId;
    this.apexHeaders.appId = appId;
    if (platform) {
      this.platform = platform;
      this.apexHeaders.platform = platform;
    }
  }
  /**
   * 网络请求头
   * @param headers
   */
  setHeaders(headers: any) {
    if (!headers) return;
    const theDefaultHeaders: any = defaultHeaders;
    for (let key in headers) {
      if (theDefaultHeaders[key] === undefined) {
        delete headers[key];
      }
    }
    this.headers = {
      ...headers,
      signVersion: "V005",
      sign: "V005",
      test: "00",
    };
  }
  /**
   * apex请求头
   * @param apexHeaders
   */
  setApexHeaders(apexHeaders: any) {
    if (!apexHeaders) return;
    this.apexHeaders = {
      ...this.apexHeaders,
      ...apexHeaders,
      platform: defaultApexHeaders.platform,
    };
  }
  /**
   * 发送事件
   * @param eventName
   * @param data
   */
  async send(name: string, data: any) {
    const apexHeaders: any = JSON.parse(JSON.stringify(this.apexHeaders));
    // 如何不是H5 则不需要上报这些字段
    if (isMigu) {
      // 移动字段
      const move: any = {
        pageIdStack: this.apexHeaders.pageIdStack,
        appSessionId: this.apexHeaders.appSessionId,
        activeSessionId: this.apexHeaders.activeSessionId,
        trackId: this.apexHeaders.trackId,
      };

      for (const key in move) {
        delete apexHeaders[key];
      }
      data = { ...data, ...move };
    }

    const logData = {
      name,
      time: new Date().getTime(),
      params: data,
    };

    return await post(apexHeaders, logData, {
      headers: this.headers,
    });
  }

  autoObserve(isOpen: boolean) {
    if (!isOpen) {
      if (typeof this._stopListen === "function") {
        this._stopListen();
      }
      this._stopListen = null;
      return;
    }
    // 测试环境不支持
    if (isTestDomain()) {
      return;
    }
    if (!this._stopListen) {
      // 捕获阶段避免 click.stop 状况
      const stopClick = addListener(
        document,
        "click",
        (event: Event) => {
          this._sendClickEvent(event);
        },
        true
      );
      // 页面跳转
      const stopPageChange = addListener(window, "popstate", () => {
        if (this.debug) {
          console.log("----popstate-----");
        }
        this._sendPageIn();
      });
      proxyPushState(() => {
        if (this.debug) {
          console.log("----pushstate-----");
        }
        this._sendPageIn();
      });

      const stopBeforeUnload = addListener(window, "beforeunload", () => {
        if (this.debug) {
          console.log("----beforeunload-----");
        }
        this._sendPageOut();
      });

      this._stopListen = () => {
        stopClick();
        stopPageChange();
        stopBeforeUnload();
        proxyPushState(() => {});
      };
    }
    this._sendPageIn();
  }

  _sendClickEvent(event: Event) {
    const target = selectTarget(event.target as HTMLElement);
    if (!target) return;
    
    const axis = getElementAxis(target);
    const content = getContentText(target);
    const pageInfo = getPageInfo();
    if (axis) {
      const logData = {
        ...pageInfo,
        vpath: axis,
        content: content,
        triggerType: "2",
      };
      this.send(this._getEventName("click"), logData);
    }
  }

  _sendPageIn() {
    const pageInfo = getPageInfo();
    const nextPage = pageInfo.page;
    const { page, startTime } = this._currentPageInfo;
    // 页面跳转
    if (page !== nextPage) {
      const endTime = new Date().getTime();
      if (page) {
        this.send(this._getEventName("pageOut"), {
          ...this._currentPageInfo,
          startTime,
          endTime,
          duration: endTime - startTime,
        });
      }
      this.send(this._getEventName("pageIn"), pageInfo);
      this._currentPageInfo = Object.assign({}, pageInfo, {
        startTime: endTime,
      });
    }
  }

  _sendPageOut() {
    const endTime = new Date().getTime();
    const { page, startTime } = this._currentPageInfo;

    if (page) {
      this.send(this._getEventName("pageOut"), {
        ...this._currentPageInfo,
        startTime,
        endTime,
        duration: endTime - startTime,
      });
    }
  }

  _getEventName(event: string) {
    let platformName = "H5";
    let eventName = "";

    switch (this.platform) {
      case "6":
        platformName = "WEB";
        break;
    }
    switch (event) {
      case "click":
        eventName = "元素";
        break;
      case "pageIn":
        eventName = "页面访问";
        break;
      case "pageOut":
        eventName = "页面离开";
        break;
    }
    return `${platformName}/${eventName}`;
  }
}
