import qs from 'qs';

const isGray = location.href.indexOf('_env=10') > -1;
const API_URL = isGray
  ? '//app.c.vip.migu.cn/log/h5binToKafka'
  : '//app.log.nf.migu.cn/log/h5binToKafka';

const ContentType = 'application/octet-stream';

/**
 * 上报数据
 * @param apexHeaders
 * @param logData
 * @param config
 */
export function post(apexHeaders: any, logData: any, config: any) {
  // 创建FormData对象
  // const body = qs.stringify({
  //   data: JSON.stringify(apexHeaders) + '\n' + JSON.stringify(logData),
  // });
  const data = [JSON.stringify(apexHeaders), '\n', JSON.stringify(logData)];
  const body = new Blob(data, {
    type: ContentType,
  });
  console.log('[apex.post.bodyData]', data, config.headers);
  fetch(API_URL, {
    method: 'POST',
    credentials: 'include', // 可以是 'same-origin' 或 'include'
    headers: {
      ...config.headers,
      'Content-Type': ContentType,

      timestamp: new Date().getTime(),
    },
    body,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('[apex.post] Network response was not ok');
      }
      return response.text();
    })
    .then((text) => {
      console.log('[apex.post.response]', text);
    })
    .catch((error) => {
      console.error('[apex.post.error]', error);
    });
}
