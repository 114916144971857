import {
  ICON_PAGE,
  ICON_SELECT,
  ICON_SELECT_ACTIVE,
  ICON_FUZZY_SELECT,
  ICON_FUZZY_ACTIVE,
} from "../selection/utils/icon";

/**
 * 创建控制按钮样式
 */
export function getControlStyle(maxZIndex: number) {
  return `
        .apex__control__box {
            position: fixed;
            right: 20px;
            top: 200px;
            z-index: ${maxZIndex + 100};
            padding: 4px 16px;
            border-radius: 24px;
            border: 1px solid #FFFFFF88;
            background-color: #037AFF33;
            display: flex;
            align-items: center;
            justify-content: center;
            user-select: none;
            cursor: move;
        }
        .apex__control__box .apex__icon {
            width: 40px;
            height: 40px;
            margin-left: 4px;
            cursor: pointer;
            border-radius: 2px;
        }
        .apex__control__box .apex__icon:first-child {
            margin-left: 0;
        }
        .apex__control__box .apex__icon.page {
            background: url('${ICON_PAGE}') no-repeat center / 24px 24px;
        }
        .apex__control__box .apex__icon.select {
            background: url('${ICON_SELECT}') no-repeat center / 24px 24px;
        }
        .apex__control__box .apex__icon.select.active {
            background: url('${ICON_SELECT_ACTIVE}') no-repeat center / 24px 24px;
        } .apex__control__box .apex__icon.fuzzy {
            background: url('${ICON_FUZZY_SELECT}') no-repeat center / 24px 24px;
        }
        .apex__control__box .apex__icon.fuzzy.active {
            background: url('${ICON_FUZZY_ACTIVE}') no-repeat center / 24px 24px;
        }
        .apex__control__box .apex__icon.exit.active {
            background-color: #fff;
        }
    `;
}
/**
 * 审查元素样式
 * @returns
 */
export function getInspectorStyle() {
  return `
        .apex-inspector {
            position: absolute;
            pointer-events: none;
            transform-origin: 0 0;
        }
        .apex-inspector > div {
            position: absolute;
            pointer-events: none;
        }
        .apex-inspector-wrapper .tips {
            max-width: 70%;
            background-color: #333740;
            font-size: 0;
            line-height: 18px;
            padding: 3px 10px;
            position: absolute;
            border-radius: 4px;
            display: none;
            pointer-events: none;
        }
        .apex-inspector-wrapper .tips .triangle {
            width: 0;
            height: 0;
            position: absolute;
            border-top: 8px solid #333740;
            border-right: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-left: 8px solid transparent;
            left: 10px;
            bottom: -16px;
        }
        .apex-inspector-wrapper .tips.reverse .triangle {
            border-top: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #333740;
            border-left: 8px solid transparent;
            left: 10px;
            top: -16px;
        }
        .apex-inspector-wrapper .tips.reverse-r .triangle {
            left: auto;
            right: 10px;
        }
        .apex-inspector-wrapper .tips > div {
            display: inline-block;
            vertical-align: middle;
            font-size: 12px;
            font-family: Consolas, Menlo, Monaco, Courier, monospace;
            overflow: auto;
        }
        .apex-inspector-wrapper .tips .tag {
            color: #e776e0;
        }
        .apex-inspector-wrapper .tips .id {
            color: #eba062;
        }
        .apex-inspector-wrapper .tips .class {
            color: #8dd2fb;
        }
        .apex-inspector-wrapper .tips .line {
            color: #fff;
        }
        .apex-inspector-wrapper .tips .size {
            color: #fff;
        }
        .apex-inspector-theme-default .margin {
            background-color: rgb(248, 204, 157, 0.75);
        }
        .apex-inspector-theme-default .border {
            background-color: rgba(250, 215, 138, 0.75);
        }
        .apex-inspector-theme-default .padding {
            background-color: rgba(182, 200, 120, 0.75);
        }
        .apex-inspector-theme-default .content {
            background-color: rgba(81, 101, 255, 0.75);
        }
    `;
}

/**
 * Toast样式
 * @returns
 */
export function getToastStyle(maxZIndex: number) {
  return `
        .apex__toast {
            position: fixed;
            top: 35%;
            left: 50%;
            transform: translate3d(-50%, 0, 0);
            padding: 5px 10px;
            z-index: ${maxZIndex + 400};
            border-radius: 30px;
            background-color: rgba(0, 0, 0, 0.75);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: #fff;
        }
    `;
}


