import {
  createStyle,
  addListener,
  getElementAxis,
  getPageInfo,
  countMaxZIndex,
} from "../utils/dom";
import { getInspectorStyle, getToastStyle } from "../const/selectionStyle";
import {
  throttle,
  loadJs,
  getElementsByXPath,
  isMobileDevice,
  debounce,
} from "../utils/help";
import { Apex } from "../Apex";
import {
  createControlBox,
  createOverlay,
  createAssist,
  addOverlay,
  createToast,
  getEleByParent,
  createEditBox,
  createGreenOverlay,
  clearGreenOverlay,
  initObserve,
  getTouchMouseTargetElement,
  checkIsSvg,
} from "./utils/dom";
import { uploadFile, getXPathList, saveXPath, deleteXPath } from "./utils/api";

declare global {
  interface Window {
    html2canvas: any;
  }
}

const maxZIndex = countMaxZIndex();
let xPathList: Record<string, any>[] = [];

/**
 * 创建圈选图片
 * @param element
 * @returns
 */
function createSelectImage(element: HTMLElement): Promise<{
  file: File;
  base64: string;
}> {
  return new Promise((resolve, reject) => {
    window
      ?.html2canvas(element, {
        useCORS: true,
        backgroundColor: null,
      })
      .then((canvas: HTMLCanvasElement) => {
        const base64 = canvas.toDataURL();
        canvas.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], "screenshot.png", {
              type: blob.type,
            });
            resolve({
              file,
              base64,
            });
          } else {
            reject(new Error("Failed to convert canvas to Blob."));
          }
        }, "image/png");
      });
  });
}

/**
 * 获取图片线上访问地址
 * @param file
 * @returns
 */
async function getImageUrl(file: File) {
  const { code, data } = await uploadFile(file);
  if (code === "000000") {
    return data?.[0]?.url;
  }
}

interface EditParams {
  type: string;
  close: Function;
  name: string;
  imgSrc: string;
  pageId: string;
  vPath: string;
}
/**
 * 控制弹窗打开
 * @param e
 * @param pause
 * @param start
 * @param currentTarget
 * @param currentXpath
 */

async function handlerOpenEdit(
  e: Event,
  pause: Function,
  start: Function,
  currentTarget: HTMLElement,
  currentXpath: string,
  apex: Apex
) {
  e.preventDefault();
  e.stopPropagation();
  pause();

  const { page } = getPageInfo();
  const { file, base64 } = await createSelectImage(currentTarget);
  let id: number;
  let name = "";
  let vaguePath = "";

  if (xPathList) {
    const isAccurate = xPathList.some((item) => {
      if (item.vpath === currentXpath) {
        id = item.id;
        name = item.name;
        return true;
      }
    });
    if (!isAccurate) {
      const _path = currentXpath.replace(/\[\d+\]/g, "");
      xPathList.some((item) => {
        if (item.vpath === _path) {
          id = item.id;
          name = item.name;
          vaguePath = _path;
          return true;
        }
      });
    }
  }
  createEditBox(
    {
      appId: apex.appId,
      pageId: page,
      name,
      isVague: !!vaguePath,
      vPath: currentXpath,
      vaguePath,
      imgSrc: base64,
    },
    maxZIndex,
    async (params: EditParams) => {
      if (params.type === "save") {
        const name = params.name.replace(/^\s|\s$/gi, "");
        if (!name) {
          return createToast("请输入名称");
        }
        const fileUrl = await getImageUrl(file);
        const { code, info } = await saveXPath({
          id,
          name,
          vpath: params.vPath,
          page,
          screenshot: fileUrl,
          appId: apex.appId,
        });
        if (code === "000000") {
          params.close();
          createToast("保存成功");
          setXPathList(apex);
        } else {
          createToast(info);
        }
      } else {
        if (!id) return createToast("没有资源可以删除，请先保存");
        const { code, info } = await deleteXPath({
          id,
        });
        if (code === "000000") {
          params.close();
          createToast("删除成功");
          setXPathList(apex);
        } else {
          createToast(info);
        }
      }
    },
    start
  );
}

/**
 * 初始化反填xpath
 * @param apex
 * @returns
 */
async function initXPathList(apex: Apex) {
  const { page } = getPageInfo();
  const { code, data, info } = await getXPathList({
    appId: apex.appId,
    page,
  });
  if (code === "000000") {
    const vPathList: string[] =
      data.map((v: Record<string, any>) => v.vpath) || [];
    clearGreenOverlay();
    vPathList?.forEach((path: string) => {
      const nodes = getElementsByXPath(path);
      nodes.forEach((node) => {
        createGreenOverlay(node as HTMLElement, maxZIndex);
      });
    });

    return data;
  } else {
    createToast(info);
  }
}

/**
 * 获取数据
 * @param apex
 */
async function setXPathList(apex: Apex) {
  xPathList = await initXPathList(apex);
}

/**
 * 初始化圈选功能
 */
export async function initSelection(apex: Apex) {
  loadJs(
    "https://y.migu.cn/app/v4/public/js/html2canvas/html2canvas-pro.min.js"
  );
  const controlEle = createControlBox(maxZIndex);
  const overlay = createOverlay(maxZIndex);
  const assistEle = createAssist();
  const inspectorStyle = getInspectorStyle();
  const toastStyle = getToastStyle(maxZIndex);

  setXPathList(apex);
  createStyle(inspectorStyle);
  createStyle(toastStyle);
  initObserve(
    debounce(() => {
      setXPathList(apex);
    }, 1500)
  );

  let isToast = false;
  let isStartSelect = false;
  let listeners: Function[] = [];
  let currentTarget: HTMLElement;
  let currentXpath: string;
  let _cachedTarget: HTMLElement;

  const _remove = () => {
    overlay.innerHTML = "";
  };
  const _onMove = (e: MouseEvent) => {
    const target = getTouchMouseTargetElement(e) as HTMLElement;
    if (target && overlay && overlay.contains(target)) return;
    if (checkIsSvg(target)) return;

    currentTarget = target;
    if (currentTarget === _cachedTarget) return null;
    _remove();
    _cachedTarget = currentTarget;
    currentXpath = getElementAxis(target);
    addOverlay({
      target: target,
      root: overlay,
      assistEle: assistEle,
      maxZIndex,
    });
  };
  const handlerMove = throttle(_onMove, 200);
  const start = () => {
    if (isStartSelect) return;
    isStartSelect = true;
    selectEle?.classList.add("active");
    exitEle?.classList.remove("active");
    if (!isToast) {
      createToast("点击您需要统计的元素");
      isToast = true;
    }

    ["mousemove"].forEach((event) => {
      listeners.push(
        addListener(document.body, event, handlerMove, {
          capture: true,
          passive: true,
        })
      );
    });
  };
  const pause = () => {
    isStartSelect = false;
    selectEle?.classList.remove("active");
    exitEle?.classList.add("active");
    listeners.forEach((listener) => {
      listener();
    });
  };

  const selectEle = getEleByParent(".select", controlEle);
  const exitEle = getEleByParent(".exit", controlEle);

  addListener(selectEle as Element, "click", start);
  addListener(exitEle as Element, "click", () => {
    pause();
    _remove();
  });

  addListener(
    document.body,
    "click",
    (e: Event) => {
      if (checkIsSvg(e?.target as HTMLElement)) return;
      if (isStartSelect) {
        if (isMobileDevice()) {
          handlerMove(e);
        }
        handlerOpenEdit(e, pause, start, currentTarget, currentXpath, apex);
      }
    },
    true
  );
}
