import { Apex } from "./Apex";
import { initSelection } from "./selection";
import { getQueryStr } from "./utils/help";
// import { getArrayParams } from "./utils/parameterParse";

declare global {
  //设置全局属性
  interface Window {
    //window对象属性
    mga: any; //加入对象
  }
}

const apex = new Apex();

if (getQueryStr("apex_debug")) {
  initSelection(apex); // 圈选初始化
}

const mga = (...args: any[]) => {
  const [name, data, config] = args;
  switch (name) {
    case "create":
      // 不执行
      apex.setAppId(data, config);
      break;
    case "send":
      apex.send(data, config);
      break;
    case "headers":
      apex.setHeaders(data);
      break;
    case "apexHeaders":
      apex.setApexHeaders(data);
      break;
    case "autoObserve":
      apex.autoObserve(data);
      break;
    case "debug":
      apex.debug = data;
      break;
  }
};

// 发送之前的事件
const mgaQ = window?.mga?.q || [];

for (let i = 0; i < mgaQ.length; i++) {
  if (mgaQ[i][0] !== "send") {
    mga(mgaQ[i][0], mgaQ[i][1], mgaQ[i][2]);
  }
}

setTimeout(() => {
  for (let i = 0; i < mgaQ.length; i++) {
    if (mgaQ[i][0] === "send") {
      mga(mgaQ[i][0], mgaQ[i][1], mgaQ[i][2]);
    }
  }
  mgaQ.length = 0;
}, 2000);

window.mga = mga;

export default apex;
