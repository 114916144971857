export class fuzzyXPath {
    /** 获取两个 XPath 的公共前缀 */
    private static getCommonPrefix(path1: string, path2: string): string {
        const parts1 = path1.split("/");
        const parts2 = path2.split("/");
        const commonParts: string[] = [];

        for (let i = 0; i < Math.min(parts1.length, parts2.length); i++) {
            if (parts1[i] === parts2[i]) {
                commonParts.push(parts1[i]);
            } else {
                break;
            }
        }
        return commonParts.join("/");
    }

    /** 判断是否属于同一个列表 (基于公共前缀) */
    private static isSameListByPrefix(xpath1: string, xpath2: string, threshold = 0.7): boolean {
        const commonPath = this.getCommonPrefix(xpath1, xpath2);
        if (commonPath.includes("ul") || commonPath.includes("ol") || commonPath.includes("div.list-container")) {
            return true;
        }
        const similarity = commonPath.length / Math.max(xpath1.length, xpath2.length);
        return similarity >= threshold;
    }

    /** 计算 Levenshtein 编辑距离 */
    private static levenshteinDistance(s1: string, s2: string): number {
        const len1 = s1.length, len2 = s2.length;
        const dp: number[][] = Array.from({ length: len1 + 1 }, () => Array(len2 + 1).fill(0));

        for (let i = 0; i <= len1; i++) dp[i][0] = i;
        for (let j = 0; j <= len2; j++) dp[0][j] = j;

        for (let i = 1; i <= len1; i++) {
            for (let j = 1; j <= len2; j++) {
                dp[i][j] = Math.min(
                    dp[i - 1][j] + 1,
                    dp[i][j - 1] + 1,
                    dp[i - 1][j - 1] + (s1[i - 1] === s2[j - 1] ? 0 : 1)
                );
            }
        }
        return dp[len1][len2];
    }

    /** 判断是否属于同一个列表 (基于 Levenshtein 距离) */
    private static isSameListByDistance(xpath1: string, xpath2: string, threshold = 0.7): boolean {
        const distance = this.levenshteinDistance(xpath1, xpath2);
        const similarity = 1 - (distance / Math.max(xpath1.length, xpath2.length));
        return similarity >= threshold;
    }

    /** 解析 XPath 并获取 DOM 节点 */
    private static getNodeByXPath(xpath: string): Element | null {
        return document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue as Element;
    }

    /** 判断是否属于同一个列表 (基于 DOM 解析) */
    private static isSameListByDOM(xpath1: string, xpath2: string): boolean {
        const node1 = this.getNodeByXPath(xpath1);
        const node2 = this.getNodeByXPath(xpath2);
        if (!node1 || !node2) return false;
        return node1.parentNode === node2.parentNode;
    }

    /** 综合判断两个 XPath 是否属于同一个列表 */
    public static isSameList(xpath1: string, xpath2: string): boolean {
        return this.isSameListByDOM(xpath1, xpath2) || 
               this.isSameListByDistance(xpath1, xpath2, 0.8) || 
               this.isSameListByPrefix(xpath1, xpath2, 0.7);
    }

     /** 生成通用 XPath，替换不同索引部分为 [**] */
     public static generateSamePath(xpath1: string, xpath2: string): string {
        const parts1 = xpath1.split("/");
        const parts2 = xpath2.split("/");
        const resultParts: string[] = [];

        for (let i = 0; i < Math.min(parts1.length, parts2.length); i++) {
            if (parts1[i] === parts2[i]) {
                resultParts.push(parts1[i]);
            } else {
                resultParts.push(parts1[i].replace(/\[\d+\]/, "[%]"));
            }
        }
        return resultParts.join("/");
    }
}
