/**
 * 获取页面参数
 * @param key
 * @returns
 */
export function getQueryStr(key: string) {
  try {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(key);
  } catch (e) {
    return;
  }
}

/**
 * 节流
 * @param func
 * @param wait
 * @returns
 */
export function throttle(func: Function, wait = 100) {
  let timeout: ReturnType<typeof setTimeout> | null;
  let elapsed;
  let lastRunTime = Date.now();
  return function none(content: any, ...args: any) {
    const _this = content;
    if (typeof timeout === "number") {
      clearTimeout(timeout);
    }
    elapsed = Date.now() - lastRunTime;
    function later() {
      lastRunTime = Date.now();
      timeout = null;
      func.call(_this, _this);
    }
    if (elapsed > wait) {
      later();
    } else {
      timeout = setTimeout(later, wait - elapsed);
    }
  };
}

/**
 * 防抖延迟
 * @param func
 * @param delay
 * @returns
 */
export function debounce(func: Function, delay: number) {
  let timer: ReturnType<typeof setTimeout>;
  return (...args: any): void => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

/**
 * 复制自身属性的值
 * @param target
 * @param source
 * @returns
 */
export function mixin(
  target: { [key: string]: any },
  source: { [key: string]: any }
) {
  const targetCopy = target;
  Object.keys(source).forEach((item) => {
    if (Object.hasOwnProperty.call(source, item)) {
      targetCopy[item] = source[item];
    }
  });
  return targetCopy;
}

/**
 * 根据XPath查找DOM元素
 * @param XPath
 * @returns
 */
export function getElementsByXPath(xpath: string): Node[] {
  const result = document.evaluate(
    /^html/gi.test(xpath) ? xpath : `/html/body/${xpath}`,
    document,
    null,
    XPathResult.ORDERED_NODE_SNAPSHOT_TYPE,
    null
  );

  const nodes: Node[] = [];
  for (let i = 0; i < result.snapshotLength; i++) {
    nodes.push(result.snapshotItem(i)!);
  }
  return nodes;
}

/**
 * 加载JS脚本函数
 * @param url
 * @returns
 */
export function loadJs(url: string) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = url;
    script.onload = () => {
      resolve(script);
    };
    script.onerror = () => {
      reject(new Error("Failed to load script " + url));
    };
    document.head.appendChild(script);
  });
}

/**
 * fetch GET参数
 * @param params
 * @returns
 */
export function buildQuery(params: Record<string, any>): string {
  return new URLSearchParams(params).toString();
}

/**
 * 检测是移动设备
 * @returns
 */
export function isMobileDevice() {
  const userAgent = navigator.userAgent;
  const isMobile =
    /android|iphone|ipad|ipod|blackberry|mobile|windows phone/i.test(userAgent);
  const isSmallScreen = window.innerWidth <= 768;
  const isTouchDevice =
    "ontouchstart" in window || navigator.maxTouchPoints > 0;

  return isMobile || (isSmallScreen && isTouchDevice);
}

/**
 * 获取页面滚动距离
 * @returns
 */
export function getScrollPosition() {
  const scrollLeft =
    window.scrollX ||
    document.documentElement.scrollLeft ||
    document.body.scrollLeft ||
    0;
  const scrollTop =
    window.scrollY ||
    document.documentElement.scrollTop ||
    document.body.scrollTop ||
    0;
  return { scrollLeft, scrollTop };
}
