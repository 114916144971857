import { uais } from "../utils/uais";
import { uuid } from "../utils/parameterParse";
import { setCookie, getCookie } from "../utils/cookie";

let deviceId = getCookie("migu-device-uuid");
if (!deviceId) {
  deviceId = uuid();
  setCookie("migu-device-uuid", deviceId, 365);
}

function isWeChatMiniProgram() {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf("miniprogram") !== -1;
}

/**
 * 请求头
 */
export const defaultHeaders = {
  channel: location.hostname === "y.migu.cn" ? "014X031" : "014021I",
  subchannel: location.hostname === "y.migu.cn" ? "014X031" : "014021I",
  "mgm-user-agent": "",
  IMEI: "h5page",
  IMSI: "h5page",
  aversionid: "",
  bversionid: "",
  "mgm-Network-type": "03",
  "mgm-Network-standard": "03",
  logId: `h5page[${(((1 + Math.random()) * 0x10000) | 0)
    .toString(16)
    .substring(1)}]`,
  uid: "",
  msisdn: "",
  "location-data": "30.6698676660,104.1229614820",
  "location-info": "",
  "mgm-network-operators": "02",
  ua: uais("ios") ? "Ios_migu" : "Android_migu",
  OAID: "",
  HWID: "",
  version: "7.8.8",
  deviceId,
  ce: "",
  appId: "h5",
  signVersion: "V005",
  sign: "V005",
  timestamp: new Date().getTime(),
};

/**
 * Apex数据头
 */
export const defaultApexHeaders = {
  deviceId,
  version: "1.0.0",
  channel: defaultHeaders.channel,
  subchannel: defaultHeaders.subchannel,
  appId: defaultHeaders.appId,
  platform: "4",
};
/*
'mgm-network-operators': '01',
version: '7.38.0',
channel: '0140070',
uid: '',
brand: '',
osVersion: '17.0.3',
appId: 'h5',
'mgm-Network-type': '04',
os: 'iOS',
subchannel: '0140070',
platform: 'iOS',
language: 'zh-Hans-CN',
logId: '1700790474.507000',
'mgm-Network-standard': '01',
ua: 'Ios_migu',
*/
