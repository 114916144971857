import { buildQuery } from "../../utils/help";
const host = "//c.musicapp.migu.cn"; //  "//218.200.229.178";

/**
 * 上传图片文件
 * @param file
 */
export async function uploadFile(file: File) {
  const formData = new FormData();
  formData.append("files", file);

  try {
    const response = await fetch(
      "//c.musicapp.migu.cn/file/upload/v1.1?serviceType=77",
      {
        method: "POST",
        body: formData,
      }
    );
    if (!response.ok) {
      throw new Error("[apex.post] Network response was not ok");
    }
    return response.json();
  } catch (error) {
    return console.error(" failed", error);
  }
}

/**
 * 查询xpath数据
 * @param params
 * @returns
 */
export async function getXPathList(params: Record<string, any>) {
  try {
    const queryString = buildQuery(params);
    const url = `${host}/apex-api/vpath-to-name/list?${queryString}`;

    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error("[apex.post] Network response was not ok");
    }
    return response.json();
  } catch (error) {
    return console.error(" failed", error);
  }
}

/**
 * 保存xPath填写记录
 * @param params
 * @returns
 */
export async function saveXPath(params: Record<string, any>) {
  try {
    const response = await fetch(`${host}/apex-api/vpath-to-name/save`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
    if (!response.ok) {
      throw new Error("[apex.post] Network response was not ok");
    }
    return response.json();
  } catch (error) {
    return console.error(" failed", error);
  }
}

/**
 * 删除XPath记录
 * @param params
 * @returns
 */
export async function deleteXPath(params: Record<string, any>) {
  try {
    const response = await fetch(`${host}/apex-api/vpath-to-name/delete`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
    if (!response.ok) {
      throw new Error("[apex.post] Network response was not ok");
    }
    return response.json();
  } catch (error) {
    return console.error(" failed", error);
  }
}
