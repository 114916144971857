import {
  ICON_SELECT,
  ICON_EXIT,
  ICON_SELECT_ACTIVE,
  ICON_DATA,
} from "../selection/utils/icon";

/**
 * 创建控制按钮样式
 */
export function getControlStyle(maxZIndex: number) {
  return `
        .apex__control__box {
            position: fixed;
            right: 0;
            top: 200px;
            z-index: ${maxZIndex + 100};
            padding: 4px 10px;
            border-radius: 4px;
            background-color: #E0E2FA;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            user-select: none;
            cursor: move;
        }
        .apex__control__box .apex__icon {
            width: 40px;
            height: 40px;
            margin-left: 10px;
            cursor: pointer;
            border-radius: 2px;
        }
        .apex__control__box .apex__icon:first-child {
            margin-left: 0;
        }
        .apex__control__box .apex__icon.select {
            background: url('${ICON_SELECT}') no-repeat center / 30px 30px;
        }
        .apex__control__box .apex__icon.select.active {
            background: url('${ICON_SELECT_ACTIVE}') no-repeat center / 30px 30px;
        }
        .apex__control__box .apex__icon.select.active {
            background-color: #fff;
        }
        .apex__control__box .apex__icon.exit {
            background: url('${ICON_EXIT}') no-repeat center / 30px 30px;
        }
        .apex__control__box .apex__icon.exit.active {
            background-color: #fff;
        }
    `;
}
/**
 * 审查元素样式
 * @returns
 */
export function getInspectorStyle() {
  return `
        .apex-inspector {
            position: absolute;
            pointer-events: none;
            transform-origin: 0 0;
        }
        .apex-inspector > div {
            position: absolute;
            pointer-events: none;
        }
        .apex-inspector-wrapper .tips {
            max-width: 70%;
            background-color: #333740;
            font-size: 0;
            line-height: 18px;
            padding: 3px 10px;
            position: absolute;
            border-radius: 4px;
            display: none;
            pointer-events: none;
        }
        .apex-inspector-wrapper .tips .triangle {
            width: 0;
            height: 0;
            position: absolute;
            border-top: 8px solid #333740;
            border-right: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-left: 8px solid transparent;
            left: 10px;
            bottom: -16px;
        }
        .apex-inspector-wrapper .tips.reverse .triangle {
            border-top: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #333740;
            border-left: 8px solid transparent;
            left: 10px;
            top: -16px;
        }
        .apex-inspector-wrapper .tips.reverse-r .triangle {
            left: auto;
            right: 10px;
        }
        .apex-inspector-wrapper .tips > div {
            display: inline-block;
            vertical-align: middle;
            font-size: 12px;
            font-family: Consolas, Menlo, Monaco, Courier, monospace;
            overflow: auto;
        }
        .apex-inspector-wrapper .tips .tag {
            color: #e776e0;
        }
        .apex-inspector-wrapper .tips .id {
            color: #eba062;
        }
        .apex-inspector-wrapper .tips .class {
            color: #8dd2fb;
        }
        .apex-inspector-wrapper .tips .line {
            color: #fff;
        }
        .apex-inspector-wrapper .tips .size {
            color: #fff;
        }
        .apex-inspector-theme-default .margin {
            background-color: rgb(248, 204, 157, 0.75);
        }
        .apex-inspector-theme-default .border {
            background-color: rgba(250, 215, 138, 0.75);
        }
        .apex-inspector-theme-default .padding {
            background-color: rgba(182, 200, 120, 0.75);
        }
        .apex-inspector-theme-default .content {
            background-color: rgba(81, 101, 255, 0.75);
        }
    `;
}

/**
 * Toast样式
 * @returns
 */
export function getToastStyle(maxZIndex: number) {
  return `
        .apex__toast {
            position: fixed;
            top: 35%;
            left: 50%;
            transform: translate3d(-50%, 0, 0);
            padding: 5px 10px;
            z-index: ${maxZIndex + 400};
            border-radius: 30px;
            background-color: rgba(0, 0, 0, 0.75);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: #fff;
        }
    `;
}

/**
 * 编辑器窗口样式
 * @returns
 */
export function getEditBoxStyle(maxZIndex: number) {
  return `
        .apex__edit__box {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: ${maxZIndex + 102};
            background-color: rgba(0, 0, 0, 0.75);
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .apex__edit__box .edit__body {
            width: 375px;
            padding: 10px;
            background-color: #F2F3FD;
            border-radius: 10px;
            border: 1px solid #7F86EA;
            box-sizing: border-box;
        }
        .apex__edit__box .edit__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
        }
        .apex__edit__box  .edit__title {
            font-size: 16px;
            font-weight: bold;
            color: #333;
        }
        .apex__edit__box  .edit__close {
            font-size: 14px;
            padding: 10px;
            color: #7F86EA;
            cursor: pointer;
        }
        .apex__edit__box .edit__content {
            font-size: 14px;
        }
        .apex__edit__box .top__content {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 1px solid #007BFF;
        }
        .apex__edit__box .item {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            word-break: break-all;
        }
        .apex__edit__box .item >span:first-child {
            margin-right: 10px;
            flex-shrink: 0;
        }
        .apex__edit__box .top__content .left .input {
            padding: 5px;
            border: 1px solid #7F86EA;
            border-radius: 5px;
            outline: none;
            padding-left: 10px;
            width: 120px;
        }
        .apex__edit__box .top__content .left .checkbox {
            width: 20px;
            height: 20px;
            color: #7F86EA;
            appearance: none;
            -webkit-appearance: none; /* Safari */
            border: 2px solid #007BFF;
            border-radius: 4px;
            box-sizing: border-box;
            position: relative;
        }
        .apex__edit__box .top__content .left .checkbox:checked {
            background-color: #7F86EA;
        }
        .apex__edit__box .top__content .left .checkbox:checked::after {
            content: '';
            position: absolute;
            top: 0;
            left: 5px;
            width: 6px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
        .apex__edit__box .top__content .right {
            width: 130px;
            max-height: 300px;
            border: 1px solid #007BFF;
            border-radius: 3px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
        }
        .apex__edit__box .top__content .right > img {
            width: 100%;
        }
        .apex__edit__box .edit__footer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        .apex__edit__box .edit__footer .control__link {
            width: 30px;
            height: 30px;
            border-radius: 5px;
            cursor: pointer;
            margin-right: auto;
            background: url('${ICON_DATA}') no-repeat center / 80% 80%;
        }
        .apex__edit__box .edit__footer .control__button {
            padding: 5px 20px;
            border-radius: 5px;
            font-size: 14px;
            cursor: pointer;
        }
        .apex__edit__box .edit__footer .control__button.save {
            background-color: #616AE5;
            color: #ffffff;
            margin-left: 10px;
        }
        .apex__edit__box .edit__footer .control__button.remove {
            background-color: #FBBFBC;
            color: #333333;
        }
    `;
}
