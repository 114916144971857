let passiveSupported = false;

try {
  const options = {
    get passive() {
      // 该函数会在浏览器尝试访问 passive 值时被调用。
      passiveSupported = true;
      return false;
    },
  };
  // @ts-ignore
  window.addEventListener("test", null, options);
  // @ts-ignore
  window.removeEventListener("test", null, options);
} catch (err) {
  passiveSupported = false;
}

/**
 * 添加dom事件
 * @param element-元素
 * @param event-事件名
 * @param listener-监听器
 * @param options-选项
 */
export function addListener(
  element: Element | Document | Window,
  event: string,
  listener: EventListenerOrEventListenerObject,
  options?: boolean | AddEventListenerOptions
) {
  let opts = options;
  if (options === false || options === undefined) {
    opts = passiveSupported ? { passive: true } : false;
  }
  element.addEventListener(event, listener, opts);

  function stop() {
    element.removeEventListener(event, listener, opts);
  }
  return stop;
}

/**
 * 获取元素坐标标记
 */
export function getElementAxis(node: Element, flag = "apex") {
  let nodeId = "";
  const path = [];

  while (node.nodeType === 1 && node.tagName.toLowerCase() !== "body") {
    const apexId = node.getAttribute(flag);
    if (apexId) {
      nodeId = apexId;
      break;
    }
    const childNodes = node.parentNode?.children || [];
    let count = 0;
    let index = 1;

    for (let i = 0; i < childNodes.length; i++) {
      const sibling = childNodes[i];
      if (sibling.nodeType === 1 && sibling.tagName === node.tagName) {
        count++;
        if (sibling === node) {
          index = count;
        }
      }
    }
    let xname = node.tagName.toLowerCase();
    if (count > 1) {
      xname += `[${index}]`;
    }
    path.unshift(xname);
    node = node.parentNode as Element;
  }
  if (nodeId) {
    return nodeId;
  }
  return path.join("/");
}

/**
 * 获取页面信息
 */
export function getPageInfo() {
  let pageUrl = window.location.href;
  const url = new URL(pageUrl);
  const { origin, hostname, pathname, hash, search } = url;

  const cFrom = hostname;
  let page = `${pathname}${search}`;

  if (hash.length > 1) {
    const hashUrl = new URL(hash.substring(1), origin);
    page = `${pathname}${search}#${hashUrl.pathname}`;
  }

  page = page.replace(/([?&])apexDebug=1/gi, ""); // 排除圈选模式本身
  pageUrl = pageUrl.replace(/([?&])apexDebug=1/gi, "");

  return {
    pageUrl,
    page,
    pageId: page,
    cFrom,
    pageFrom: cFrom,
    activityId: "",
  };
}

/**
 * 计算页面最大zIndex
 * @returns
 */
export function countMaxZIndex() {
  const elements = Array.from(document.querySelectorAll("*"));
  return elements.reduce(
    (r, e) => Math.max(r, +window.getComputedStyle(e).zIndex || 0),
    1
  );
}

/**
 * 创建DOM元素
 * @param tag
 * @param attr
 * @param content
 * @returns
 */
export function createElement(
  tag: string,
  attr: { [key: string]: string },
  content?: string
) {
  const ele = document.createElement(tag);
  Object.keys(attr).forEach((item) => {
    ele.setAttribute(item, attr[item]);
  });
  if (content) ele.innerHTML = content;
  return ele;
}

/**
 * 动态创建CSS样式表
 * @param css
 */
export function createStyle(css: string) {
  const style = createElement("style", { type: "text/css" });
  style.textContent = css;
  document.head.appendChild(style);
}
