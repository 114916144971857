export function uais(name: string): boolean {
  let ua = navigator.userAgent.toLowerCase();
  let is: any = false;
  switch (name) {
    case 'weixin':
      is = ua.match(/(micromessenger);?/i);
      break;
    case 'ios':
      is = ua.match(/(iphone|ipod|ipad|mac);?/i);
      break;
    case 'browser':
      is = ua.match(/(applewebkit);?/i);
      break;
    case 'chrome':
      is = ua.match(/(?:chrome|crios)\/([\d\.]+)/);
      break;
    //如果为咪咕客户端
    case 'migu':
      is = uais('android-migu') || uais('ios-migu');
      break;
    case 'ios-migu':
      is = uais('iphone-migu') || uais('ipad-migu');
      break;
    case 'ipad-migu':
      is = ua.match(/(miguipadmusic);?/i);
      break;

    default:
      is = ua.indexOf(name) > -1;
  }
  return is ? true : false;
}
