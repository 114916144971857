import { createElement, createStyle, addListener } from "../../utils/dom";
import { getControlStyle, getEditBoxStyle } from "../../const/selectionStyle";
import { mixin, getScrollPosition } from "../../utils/help";
import { enableDrag } from "./drag";

/**
 * 创建编辑弹窗
 * @returns
 */
export function createEditBox(
  {
    appId,
    name,
    imgSrc,
    isVague = false,
    pageId,
    vPath,
    vaguePath,
  }: {
    appId: string;
    name?: string;
    imgSrc: string;
    isVague?: boolean;
    pageId: string;
    vPath: string;
    vaguePath?: string;
  },
  maxZIndex: number,
  confirmCallback?: Function,
  closeCallback?: Function
) {
  const style = getEditBoxStyle(maxZIndex);
  const fragment = document.createDocumentFragment();
  const wrapper = createElement(
    "div",
    {
      class: "apex__edit__box",
    },
    `
        <div class="edit__body">
          <div class="edit__header">
            <div class="edit__title">定义元素</div>
            <div class="edit__close">X</div>
          </div>
          <div class="edit__content">
            <div class="top__content">
              <div class="left">
                <div class="item">
                  <span>名称: </span>
                  <input class="input" type="text" value="${name}" placeholder="请输入名称">
                </div>
                <div class="item">
                  <span>触发类型: </span>
                  <span>点击</span>
                </div>
                <div class="item" style="display: none">
                  <span>模糊匹配: </span>
                  <input class="checkbox" ${
                    isVague ? "checked" : ""
                  } type="checkbox">
                </div>
              </div>
              <div class="right"><img src="${imgSrc}" /></div>
            </div>
            <div class="bottom__content">
              <div class="item">
                <span>PageId:</span>
                <span>${pageId}</span>
              </div>
              <div class="item">
                <span>VPath:</span>
                <span id="vPath">${vaguePath || vPath}</span>
              </div>
            </div>
          </div>
          <div class="edit__footer">
            <div class="control__link"></div>
            <div class="control__button remove">删除</div>
            <div class="control__button save">保存</div>
          </div>
        </div>
      `
  );
  createStyle(style);
  fragment.appendChild(wrapper);
  document.documentElement.appendChild(fragment);

  const closeEle = getEleByParent(".edit__close", wrapper) as Element;
  const removeEle = getEleByParent(".remove", wrapper) as Element;
  const saveEle = getEleByParent(".save", wrapper) as Element;
  const linkEle = getEleByParent(".control__link", wrapper) as Element;
  const checkboxEle = getEleByParent(".checkbox", wrapper) as HTMLInputElement;
  const vPathEle = getEleByParent("#vPath", wrapper) as HTMLInputElement;
  let tmpPath = vPath;

  const getNameValue = () => {
    return (getEleByParent(".input", wrapper) as HTMLInputElement).value;
  };
  const close = () => {
    document.documentElement.removeChild(wrapper);
    closeCallback?.();
  };
  const callback = (type: string) => {
    return confirmCallback?.({
      type,
      close,
      name: getNameValue(),
      imgSrc,
      pageId,
      vPath,
    });
  };
  addListener(checkboxEle, "change", () => {
    if (checkboxEle.checked) {
      vPath = vaguePath || vPath.replace(/\[\d+\]/g, "");
    } else {
      vPath = tmpPath;
    }
    vPathEle.innerText = vPath;
  });
  addListener(closeEle, "click", close);
  addListener(removeEle, "click", () => callback("remove"));
  addListener(saveEle, "click", () => callback("save"));
  addListener(linkEle, "click", () => {
    const targetURL = `http://ping.migu.cn/apex/#/boards?id=293&aid=${encodeURIComponent(
      appId
    )}&vpath=${encodeURIComponent(tmpPath)}&fuzzy_vpath=${encodeURIComponent(
      vPath
    )}`;
    if (window.top && window.self !== window.top) {
      window.top.location.href = targetURL;
    } else {
      window.location.href = targetURL;
    }
  });
  return wrapper;
}

/**
 * 创建控制条DOM
 */
export function createControlBox(maxZIndex: number) {
  const style = getControlStyle(maxZIndex);
  const fragment = document.createDocumentFragment();
  const wrapper = createElement(
    "div",
    {
      class: "apex__control__box",
    },
    `<div class="apex__icon select"></div><div class="apex__icon exit"></div>`
  );
  createStyle(style);
  fragment.appendChild(wrapper);
  document.documentElement.appendChild(fragment);
  enableDrag(wrapper);
  return wrapper;
}

/**
 * 判断是否是SVG元素
 * @param element
 * @returns
 */
export function checkIsSvg(element: HTMLElement) {
  return ["svg", "path", "g", "rect", "circle", "line"].includes(
    element.nodeName
  );
}

/**
 * 创建绿色这遮罩
 * @param element
 */
export function createGreenOverlay(element: HTMLElement, maxZIndex: number) {
  const rect = element.getBoundingClientRect();
  const { scrollLeft, scrollTop } = getScrollPosition();
  const wrapper = createElement("div", {
    class: "apex__xpath",
    style: `position:absolute;top:${rect.top + scrollTop}px;left:${
      rect.left + scrollLeft
    }px;width:${rect.width}px;height:${
      rect.height
    }px;background-color:rgba(0, 255, 0, 0.5);pointer-events:none;z-index:${
      maxZIndex + 10
    }`,
  });
  document.documentElement.appendChild(wrapper);
}

/**
 * 清空遮罩层
 */
export function clearGreenOverlay() {
  const elements = document.querySelectorAll(".apex__xpath");

  elements.forEach((element) => {
    element.remove();
  });
}

/**
 * 创建Toast
 * @param info
 */
export function createToast(info: string) {
  const fragment = document.createDocumentFragment();
  const wrapper = createElement(
    "div",
    {
      class: "apex__toast",
    },
    `<div class="toast__content">${info}</div>`
  );
  fragment.appendChild(wrapper);
  document.documentElement.appendChild(fragment);
  setTimeout(() => {
    document.documentElement.removeChild(wrapper);
  }, 1500);
}

/**
 * 创建操作maker
 */
export function createOverlay(maxZIndex: number) {
  const overlay = createElement("div", {
    id: "apex-inspector-root",
    style: `z-index: ${maxZIndex};`,
  });
  document.documentElement.appendChild(overlay);
  return overlay;
}

/**
 * 创建辅助元素，用于判断圈选器元素是否被缩放
 * @returns
 */
export function createAssist() {
  let assist = createElement("div", {
    style: `pointer-events: none;
            visibility: hidden;
            width: 100px;
            height: 100px;
            position: absolute;
            top: -100px;`,
  });
  document.documentElement.appendChild(assist);
  return assist;
}

/**
 * 判断是否是DOM元素
 * @param element
 * @returns
 */
function isDOM(element: HTMLElement) {
  return (
    element &&
    typeof element === "object" &&
    element.nodeType === 1 &&
    typeof element.style === "object" &&
    typeof element.ownerDocument === "object"
  );
}

/**
 * 获取移动时的目标节点元素
 * @param e
 * @returns
 */
export function getTouchMouseTargetElement(e: TouchEvent | MouseEvent) {
  if (e instanceof TouchEvent && e.touches) {
    const changedTouch = e.changedTouches[0];
    return document.elementFromPoint(
      changedTouch.clientX,
      changedTouch.clientY
    );
  }
  return e.target;
}

/**
 * 获取某个元素的子元素
 * @param selector
 * @param parent
 * @returns
 */
export function getEleByParent(selector: string, parent: HTMLElement) {
  if (parent !== undefined && isDOM(parent)) {
    return parent.querySelector(selector);
  }
  return document.querySelector(selector);
}

/**
 * 计算缩放比例
 * @param ele
 * @returns
 */
function getScale(ele: HTMLElement) {
  const pos = ele.getBoundingClientRect();
  const scaleX = Number(
    (ele.offsetWidth === undefined ? 1 : ele.offsetWidth / pos.width).toFixed(1)
  );
  const scaleY = Number(
    (ele.offsetHeight === undefined
      ? 1
      : ele.offsetHeight / pos.height
    ).toFixed(1)
  );

  return {
    scaleX,
    scaleY,
  };
}

/**
 * 获取元素位置
 * @param ele
 * @returns
 */
function findPos(ele: HTMLElement) {
  const computedStyle = window.getComputedStyle(ele);
  const pos = ele.getBoundingClientRect();
  const { scaleX, scaleY } = getScale(ele);

  const x =
    pos.left * scaleX -
    parseFloat(computedStyle.getPropertyValue("margin-left"));
  const y =
    pos.top * scaleY - parseFloat(computedStyle.getPropertyValue("margin-top"));
  const r =
    pos.right * scaleX +
    parseFloat(computedStyle.getPropertyValue("margin-right"));

  return {
    top: y,
    left: x,
    right: r,
    scaleX,
    scaleY,
  };
}

/**
 * 获取元素: 内边距、外边距、宽高等数据
 * @param ele
 * @returns
 */
function getElementInfo(ele: HTMLElement) {
  const result: Record<string, any> = {};
  const requiredValue = [
    "border-top-width",
    "border-right-width",
    "border-bottom-width",
    "border-left-width",
    "margin-top",
    "margin-right",
    "margin-bottom",
    "margin-left",
    "padding-top",
    "padding-right",
    "padding-bottom",
    "padding-left",
    "z-index",
  ];

  const computedStyle = getComputedStyle(ele);
  requiredValue.forEach((item) => {
    result[item] = parseFloat(computedStyle.getPropertyValue(item)) || 0;
  });

  const info = ele.getBoundingClientRect();

  // FIXME: 简单兼容svg元素offsetWidth, offsetHeight 为空的场景
  // TODO: 需要判断Svg元素的box-sizing，来决定其width,height是否需要减去padding, border
  const width =
    ele.offsetWidth === undefined
      ? info.width
      : ele.offsetWidth -
        result["border-left-width"] -
        result["border-right-width"] -
        result["padding-left"] -
        result["padding-right"];

  const height =
    ele.offsetHeight === undefined
      ? info.height
      : ele.offsetHeight -
        result["border-top-width"] -
        result["border-bottom-width"] -
        result["padding-top"] -
        result["padding-bottom"];

  mixin(result, {
    width,
    height,
    ...findPos(ele),
  });

  return result;
}

/**
 * 创建圈选包围的元素, 创建 parent的子元素
 * @param parent
 * @param className
 * @param content
 * @returns
 */
function createSurroundEle(
  parent: HTMLElement,
  className: string,
  content?: string
) {
  const ele = createElement(
    "div",
    {
      class: className,
    },
    content
  );
  parent.appendChild(ele);
  return ele;
}

/**
 * 元素添加style样式
 * @param element
 * @param styleObject
 */
function setStyleProperty(
  element: HTMLElement,
  styleObject: { [key: string]: any }
) {
  Object.keys(styleObject).forEach((item) => {
    element.style.setProperty(item, styleObject[item]);
  });
}

/**
 * 创建圈选蒙层
 * @param params
 * @returns
 */
export function addOverlay({
  target,
  root,
  assistEle,
  id = "apex-inspector",
  theme = "apex-inspector-theme-default",
  maxZIndex,
}: {
  target: HTMLElement;
  root: HTMLElement;
  assistEle: HTMLElement;
  id?: string;
  theme?: string;
  maxZIndex: number;
}) {
  if (!target) return null;
  const wrapper = createElement("div", {
    style: `z-index: ${maxZIndex}`,
    class: "apex-inspector-wrapper",
  });
  const parent = createElement("div", {
    id,
    class: `apex-inspector ${theme}`,
    style: `z-index: ${maxZIndex}`,
  });
  wrapper.appendChild(parent);
  const { scrollLeft, scrollTop } = getScrollPosition();

  const overlay = {
    parent,
    content: createSurroundEle(parent, "content"),
    paddingTop: createSurroundEle(parent, "padding padding-top"),
    paddingRight: createSurroundEle(parent, "padding padding-right"),
    paddingBottom: createSurroundEle(parent, "padding padding-bottom"),
    paddingLeft: createSurroundEle(parent, "padding padding-left"),
    borderTop: createSurroundEle(parent, "border border-top"),
    borderRight: createSurroundEle(parent, "border border-right"),
    borderBottom: createSurroundEle(parent, "border border-bottom"),
    borderLeft: createSurroundEle(parent, "border border-left"),
    marginTop: createSurroundEle(parent, "margin margin-top"),
    marginRight: createSurroundEle(parent, "margin margin-right"),
    marginBottom: createSurroundEle(parent, "margin margin-bottom"),
    marginLeft: createSurroundEle(parent, "margin margin-left"),
    tips: createSurroundEle(
      wrapper,
      "tips",
      `
        <div class="tag"></div>
        <div class="id"></div>
        <div class="class"></div>
        <div class="line">&nbsp;|&nbsp;</div>
        <div class="size"></div>
        <div class="triangle"></div>
      `
    ),
  };

  const { scaleX: scaleXP, scaleY: scaleYP } = getScale(assistEle);
  const { scaleX: scaleXE, scaleY: scaleYE } = getScale(target);
  const targetInfo = getElementInfo(target);
  const contentLevel = {
    width: targetInfo.width,
    height: targetInfo.height,
  };
  const paddingLevel = {
    width:
      targetInfo["padding-left"] +
      contentLevel.width +
      targetInfo["padding-right"],
    height:
      targetInfo["padding-top"] +
      contentLevel.height +
      targetInfo["padding-bottom"],
  };
  const borderLevel = {
    width:
      targetInfo["border-left-width"] +
      paddingLevel.width +
      targetInfo["border-right-width"],
    height:
      targetInfo["border-top-width"] +
      paddingLevel.height +
      targetInfo["border-bottom-width"],
  };
  const marginLevel = {
    width:
      targetInfo["margin-left"] +
      borderLevel.width +
      targetInfo["margin-right"],
    height:
      targetInfo["margin-top"] +
      borderLevel.height +
      targetInfo["margin-bottom"],
  };

  // 绘制圈选元素整个盒子
  setStyleProperty(overlay.parent, {
    width: `${marginLevel.width}px`,
    height: `${marginLevel.height}px`,
    top: `${(targetInfo.top / scaleYE) * scaleYP + scrollTop}px`,
    left: `${(targetInfo.left / scaleXE) * scaleXP + scrollLeft}px`,
  });

  if (scaleXE !== scaleXP || scaleYE !== scaleYP) {
    setStyleProperty(overlay.parent, {
      transform: `scale(${scaleXP / scaleXE}, ${scaleYP / scaleYE})`,
    });
  }

  // 绘制圈选内容盒子
  setStyleProperty(overlay.content, {
    width: `${contentLevel.width}px`,
    height: `${contentLevel.height}px`,
    top: `${
      targetInfo["margin-top"] +
      targetInfo["border-top-width"] +
      targetInfo["padding-top"]
    }px`,
    left: `${
      targetInfo["margin-left"] +
      targetInfo["border-left-width"] +
      targetInfo["padding-left"]
    }px`,
  });

  // 绘制圈选元素的四边的内边距
  setStyleProperty(overlay.paddingTop, {
    width: `${paddingLevel.width}px`,
    height: `${targetInfo["padding-top"]}px`,
    top: `${targetInfo["margin-top"] + targetInfo["border-top-width"]}px`,
    left: `${targetInfo["margin-left"] + targetInfo["border-left-width"]}px`,
  });
  setStyleProperty(overlay.paddingRight, {
    width: `${targetInfo["padding-right"]}px`,
    height: `${paddingLevel.height - targetInfo["padding-top"]}px`,
    top: `${
      targetInfo["padding-top"] +
      targetInfo["margin-top"] +
      targetInfo["border-top-width"]
    }px`,
    right: `${targetInfo["margin-right"] + targetInfo["border-right-width"]}px`,
  });
  setStyleProperty(overlay.paddingBottom, {
    width: `${paddingLevel.width - targetInfo["padding-right"]}px`,
    height: `${targetInfo["padding-bottom"]}px`,
    bottom: `${
      targetInfo["margin-bottom"] + targetInfo["border-bottom-width"]
    }px`,
    right: `${
      targetInfo["padding-right"] +
      targetInfo["margin-right"] +
      targetInfo["border-right-width"]
    }px`,
  });
  setStyleProperty(overlay.paddingLeft, {
    width: `${targetInfo["padding-left"]}px`,
    height: `${
      paddingLevel.height -
      targetInfo["padding-top"] -
      targetInfo["padding-bottom"]
    }px`,
    top: `${
      targetInfo["padding-top"] +
      targetInfo["margin-top"] +
      targetInfo["border-top-width"]
    }px`,
    left: `${targetInfo["margin-left"] + targetInfo["border-left-width"]}px`,
  });

  //  绘制圈选元素的四边的边框
  setStyleProperty(overlay.borderTop, {
    width: `${borderLevel.width}px`,
    height: `${targetInfo["border-top-width"]}px`,
    top: `${targetInfo["margin-top"]}px`,
    left: `${targetInfo["margin-left"]}px`,
  });
  setStyleProperty(overlay.borderRight, {
    width: `${targetInfo["border-right-width"]}px`,
    height: `${borderLevel.height - targetInfo["border-top-width"]}px`,
    top: `${targetInfo["margin-top"] + targetInfo["border-top-width"]}px`,
    right: `${targetInfo["margin-right"]}px`,
  });
  setStyleProperty(overlay.borderBottom, {
    width: `${borderLevel.width - targetInfo["border-right-width"]}px`,
    height: `${targetInfo["border-bottom-width"]}px`,
    bottom: `${targetInfo["margin-bottom"]}px`,
    right: `${targetInfo["margin-right"] + targetInfo["border-right-width"]}px`,
  });
  setStyleProperty(overlay.borderLeft, {
    width: `${targetInfo["border-left-width"]}px`,
    height: `${
      borderLevel.height -
      targetInfo["border-top-width"] -
      targetInfo["border-bottom-width"]
    }px`,
    top: `${targetInfo["margin-top"] + targetInfo["border-top-width"]}px`,
    left: `${targetInfo["margin-left"]}px`,
  });

  // 绘制圈选元素的四边的外边距
  setStyleProperty(overlay.marginTop, {
    width: `${marginLevel.width}px`,
    height: `${targetInfo["margin-top"]}px`,
    top: 0,
    left: 0,
  });
  setStyleProperty(overlay.marginRight, {
    width: `${targetInfo["margin-right"]}px`,
    height: `${marginLevel.height - targetInfo["margin-top"]}px`,
    top: `${targetInfo["margin-top"]}px`,
    right: 0,
  });
  setStyleProperty(overlay.marginBottom, {
    width: `${marginLevel.width - targetInfo["margin-right"]}px`,
    height: `${targetInfo["margin-bottom"]}px`,
    bottom: 0,
    right: `${targetInfo["margin-right"]}px`,
  });
  setStyleProperty(overlay.marginLeft, {
    width: `${targetInfo["margin-left"]}px`,
    height: `${
      marginLevel.height -
      targetInfo["margin-top"] -
      targetInfo["margin-bottom"]
    }px`,
    top: `${targetInfo["margin-top"]}px`,
    left: 0,
  });

  // 设置tip的显示
  const tagEle = getEleByParent(".tag", overlay.tips);
  if (tagEle) {
    tagEle.innerHTML = target.tagName.toLowerCase();
  }
  const idEle = getEleByParent(".id", overlay.tips);
  if (idEle) {
    idEle.innerHTML = target.id ? `#${target.id}` : "";
  }
  const sizeEle = getEleByParent(".size", overlay.tips);
  if (sizeEle) {
    sizeEle.innerHTML = `${marginLevel.width / scaleXE}x${
      marginLevel.height / scaleYE
    }`;
  }

  let tipsTop = 0;
  const tipsOrigin = ["top", "left"];
  if (targetInfo.top / scaleYE >= 24 + 8) {
    overlay.tips.classList.remove("reverse");
    tipsTop = (targetInfo.top / scaleYE - 24 - 8) * scaleYP;
    tipsOrigin[0] = "top";
  } else {
    overlay.tips.classList.add("reverse");
    tipsTop = ((marginLevel.height + targetInfo.top) / scaleYE + 8) * scaleYP;
    tipsOrigin[0] = "top";
  }

  const lr = {
    left: "auto",
    right: "auto",
  };
  if ((targetInfo.left / scaleXE) * scaleXP > document.body.clientWidth * 0.7) {
    overlay.tips.classList.add("reverse-r");
    tipsOrigin[1] = "right";
    lr.right = `${
      document.body.clientWidth - (targetInfo.right / scaleXE) * scaleXP
    }px`;
  } else {
    overlay.tips.classList.remove("reverse-r");
    lr.left = `${(targetInfo.left / scaleXE) * scaleXP}px`;
    tipsOrigin[1] = "left";
  }
  setStyleProperty(overlay.tips, {
    top: `${tipsTop + scrollTop}px`,
    ...lr,
    display: "block",
    "z-index": maxZIndex + 88,
  });
  if (Number(scaleXP) !== 1 || Number(scaleYP) !== 1) {
    setStyleProperty(overlay.tips, {
      transform: `scale(${scaleXP}, ${scaleYP})`,
      "transform-origin": `${tipsOrigin[0]} ${tipsOrigin[1]}`,
    });
  }
  root.appendChild(wrapper);
}

/**
 * 页面DOM监听
 */
export function initObserve(observerCallback: Function) {
  const targetNode = document.body;
  const config = {
    childList: true,
    subtree: true,
    attributes: true,
    attributeFilter: ["style"],
    attributeOldValue: true,
    characterData: false,
  };

  const callback = (mutationsList: MutationRecord[]) => {
    mutationsList.forEach((mutation) => {
      switch (mutation.type) {
        case "childList":
          observerCallback();
          break;
        case "attributes":
          if (mutation.attributeName === "style") {
            const target = mutation.target;
            const computedStyle = getComputedStyle(target as HTMLElement);
            const newDisplay = computedStyle.display;
            const newOpacity = computedStyle.opacity;

            if (mutation.oldValue) {
              const oldDisplay =
                mutation.oldValue.match(/display\s*:\s*([^;]+)/)?.[1] || "";
              const oldOpacity =
                mutation.oldValue.match(/opacity\s*:\s*([^;]+)/)?.[1] || "";

              if (oldDisplay && oldDisplay !== newDisplay) {
                console.log(
                  `display 发生了变化: ${oldDisplay} -> ${newDisplay}`
                );
                observerCallback();
              }
              if (oldOpacity && oldOpacity !== newOpacity) {
                console.log(
                  `opacity 发生了变化: ${oldOpacity} -> ${newOpacity}`
                );
                observerCallback();
              }
            }
          }
          break;
        default:
          console.log("其他类型变化：", mutation);
          break;
      }
    });
  };
  const observer = new MutationObserver(callback);
  observer.observe(targetNode, config);
}
