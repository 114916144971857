let stateChangeBefore: any;
const originPushState = window.history.pushState;
const originReplaceState = window.history.replaceState;

// biome-ignore lint/complexity/useArrowFunction: <explanation>
history.pushState = function () {
  if (typeof stateChangeBefore === "function") {
    stateChangeBefore();
  }
  // 调用原始的 pushState 方法
  // @ts-ignore
  return originPushState.apply(history, arguments);
};

// biome-ignore lint/complexity/useArrowFunction: <explanation>
history.replaceState = function () {
  if (typeof stateChangeBefore === "function") {
    stateChangeBefore();
  }
  // 调用原始的 originReplaceState 方法
  // @ts-ignore
  return originReplaceState.apply(history, arguments);
};

/**
 *  代理pushState
 */
// biome-ignore lint/complexity/noBannedTypes: <explanation>
export function proxyPushState(fn: Function) {
  stateChangeBefore = fn;
}
