import { addListener } from "../../utils/dom";

export function enableDrag(element: HTMLElement) {
  let isDragging = false;
  let startX = 0;
  let startY = 0;
  const listeners: Function[] = [];

  const onDragStart = (event: MouseEvent | TouchEvent) => {
    event.preventDefault();
    event.stopPropagation();
    isDragging = true;

    const { clientX, clientY } = getEventPosition(event);
    startX = clientX - element.offsetLeft;
    startY = clientY - element.offsetTop;

    ["mousemove", "mouseup", "touchmove", "touchend"].forEach((eventName) => {
      listeners.push(
        addListener(
          document,
          eventName,
          /move$/gi.test(eventName) ? (onDragging as EventListener) : onDragEnd,
          { passive: false }
        )
      );
    });
  };

  const onDragging = (event: MouseEvent | TouchEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (!isDragging) return;

    const { clientX, clientY } = getEventPosition(event);
    const { width, height } = element.getBoundingClientRect();
    const { clientWidth: winWidth, clientHeight: winHeight } =
      document.documentElement;

    let left = clientX - startX;
    let top = clientY - startY;

    if (top <= 0) {
      top = 0;
    }
    if (top + height >= winHeight) {
      top = winHeight - height;
    }
    if (left + width >= winWidth) {
      left = winWidth - width;
    }
    if (left <= 0) {
      left = 0;
    }

    element.style.left = `${left}px`;
    element.style.top = `${top}px`;
    element.style.right = "auto";
  };

  const onDragEnd = () => {
    isDragging = false;
    listeners.forEach((listener) => {
      listener();
    });
  };

  const getEventPosition = (
    event: MouseEvent | TouchEvent
  ): { clientX: number; clientY: number } => {
    if (event instanceof MouseEvent) {
      return { clientX: event.clientX, clientY: event.clientY };
    } else if (event instanceof TouchEvent && event.touches.length > 0) {
      return {
        clientX: event.touches[0].clientX,
        clientY: event.touches[0].clientY,
      };
    }
    return { clientX: 0, clientY: 0 };
  };

  ["mousedown", "touchstart"].forEach((eventName) => {
    addListener(element, eventName, onDragStart as EventListener);
  });
}
