import {
  createStyle,
  addListener,
  selectTarget,
  getElementAxis,
  getPageInfo,
  countMaxZIndex,
  getContentText,
} from "../utils/dom";
import { getInspectorStyle, getToastStyle } from "../const/selectionStyle";
import {
  throttle,
  loadJs,
  getElementsByXPath,
  isMobileDevice,
  debounce,
} from "../utils/help";
import { Apex } from "../Apex";
import { fuzzyXPath } from '../utils/fuzzyXPath';

import {
  createControlBox,
  createOverlay,
  createAssist,
  addOverlay,
  createToast,
  getEleByParent,
  createGreenOverlay,
  clearGreenOverlay,
  initObserve,
  getTouchMouseTargetElement,
  checkIsSvg,
} from "./utils/dom";
import { uploadFile, getXPathList, saveXPath, deleteXPath } from "./utils/api";
import { encode } from "punycode";

declare global {
  interface Window {
    html2canvas: any;
  }
}

const maxZIndex = countMaxZIndex();
// let xPathList: Record<string, any>[] = [];

/**
 * 创建圈选图片
 * @param element
 * @returns
 */
function createSelectImage(element: HTMLElement): Promise<{
  file: File;
  base64: string;
}> {
  return new Promise((resolve, reject) => {
    window
      ?.html2canvas(element, {
        useCORS: true,
        backgroundColor: null,
      })
      .then((canvas: HTMLCanvasElement) => {
        const base64 = canvas.toDataURL();
        canvas.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], "screenshot.png", {
              type: blob.type,
            });
            resolve({
              file,
              base64,
            });
          } else {
            reject(new Error("Failed to convert canvas to Blob."));
          }
        }, "image/png");
      });
  });
}

/**
 * 获取图片线上访问地址
 * @param file
 * @returns
 */
async function getImageUrl(file: File) {
  const { code, data } = await uploadFile(file);
  if (code === "000000") {
    return data?.[0]?.url;
  }
}

interface EditParams {
  type: string;
  close: Function;
  name: string;
  imgSrc: string;
  pageId: string;
  vPath: string;
}
/**
 * 控制弹窗打开
 */

async function handlerOpenEdit(
  e: Event,
  target: HTMLElement,
  xpath: string,
  vpathlike: boolean,
  content: string,
  apex: Apex
) {
  e.preventDefault();
  e.stopPropagation();

  const { page } = getPageInfo();
  const { file, base64 } = await createSelectImage(target);
  const url = window.location.href;
  window.open(`http://ping.migu.cn/apex/#/full-collector?t=v&appId=${encodeURIComponent(apex.appId)}&page=${encodeURIComponent(page)}&vpath=${encodeURIComponent(xpath)}&vpathlike=${vpathlike}&content=${encodeURIComponent(content)}&u=${encodeURIComponent(url)}&capture=${encodeURIComponent(base64)}`, '_blank');
}

/**
 * 初始化反填xpath
 * @param apex
 * @returns
 */
// async function initXPathList(apex: Apex) {
//   const { page } = getPageInfo();
//   const { code, data, info } = await getXPathList({
//     appId: apex.appId,
//     page,
//   });
//   if (code === "000000") {
//     const vPathList: string[] =
//       data.map((v: Record<string, any>) => v.vpath) || [];
//     clearGreenOverlay();
//     vPathList?.forEach((path: string) => {
//       const nodes = getElementsByXPath(path);
//       nodes.forEach((node) => {
//         createGreenOverlay(node as HTMLElement, maxZIndex);
//       });
//     });

//     return data;
//   } else {
//     createToast(info);
//   }
// }

/**
 * 获取数据
 * @param apex
 */
// async function setXPathList(apex: Apex) {
//   xPathList = await initXPathList(apex);
// }

/**
 * 初始化圈选功能
 */
export async function initSelection(apex: Apex) {
  loadJs(
    "https://y.migu.cn/app/v4/public/js/html2canvas/html2canvas-pro.min.js"
  );
  const toastStyle = getToastStyle(maxZIndex);
  createStyle(toastStyle);
  if (window.innerWidth <= 768) {
    createToast("请复制链接到pc端进行取数");
    return;
  }
  const controlEle = createControlBox(maxZIndex);
  const overlay = createOverlay(maxZIndex);
  const assistEle = createAssist();
  const inspectorStyle = getInspectorStyle();
  // setXPathList(apex);
  createStyle(inspectorStyle);
  // initObserve(
  //   debounce(() => {
  //     setXPathList(apex);
  //   }, 1500)
  // );

  let isStartSelect = false;
  let isFuzzyStartSelect = false;

  let listeners: Function[] = [];
  let _cachedTarget: HTMLElement;
  let fuzzyXpath: string[] = [];

  const _remove = () => {
    overlay.innerHTML = "";
  };
  const _onMove = (e: MouseEvent) => {

    let target = getTouchMouseTargetElement(e) as HTMLElement | null;
    target = selectTarget(target);
    if (!target) return;
    if (target && overlay && overlay.contains(target)) return;
    if (checkIsSvg(target)) return;

    if (target === _cachedTarget) return null;
    _remove();
    _cachedTarget = target;
    addOverlay({
      target: target,
      root: overlay,
      assistEle: assistEle,
      maxZIndex,
    });
  };
  const handlerMove = throttle(_onMove, 200);
  const pause = () => {
    isStartSelect = false;
    selectEle?.classList.remove("active");
    listeners.forEach((listener) => {
      listener();
    });
  };


  const fuzzypause = () => {
    isFuzzyStartSelect = false;
    fuzzyXpath = [];
    fuzzyEle?.classList.remove("active");
    listeners.forEach((listener) => {
      listener();
    });
  };

  const fuzzyStart = () => {
    if (isStartSelect) {
      createToast("请先关闭其他圈选功能");
      return;
    }
    if (isFuzzyStartSelect) {
      fuzzypause();
      _remove();
      createToast("关闭相似视图view圈选");
      return;
    }

    isFuzzyStartSelect = true;
    fuzzyEle?.classList.add("active");
    createToast("连续选择两个你需要统计的视图view");
    ["mousemove"].forEach((event) => {
      listeners.push(
        addListener(document.body, event, handlerMove, {
          capture: true,
          passive: true,
        })
      );
    });
  };
  const start = () => {
    if (isFuzzyStartSelect) {
      createToast("请先关闭其他圈选功能");
      return;
    }
    if (isStartSelect) {
      pause();
      _remove();
      createToast("关闭单个视图view圈选");
      return;
    }

    isStartSelect = true;
    selectEle?.classList.add("active");
    createToast("点击你需要统计的视图view");
    ["mousemove"].forEach((event) => {
      listeners.push(
        addListener(document.body, event, handlerMove, {
          capture: true,
          passive: true,
        })
      );
    });
  };


  // 修改 confirmFuzzy 方法
  const confirmFuzzy = (e: Event, target: HTMLElement) => {
    const sim = fuzzyXPath.isSameList(fuzzyXpath[0], fuzzyXpath[1]);
    const str = sim ? "匹配成功，点下一步" : "匹配失败，两个view不是同一类，请重新选择";
    const message = `${str}<br><br>路径1 ：${fuzzyXpath[0]}<br>路径2 ：${fuzzyXpath[1]}`;

    const buttonText = sim ? "下一步" : "重新选择";

    const confirmDiv = document.createElement('div');
    confirmDiv.style.position = 'fixed';
    confirmDiv.style.top = '50%';
    confirmDiv.style.left = '50%';
    confirmDiv.style.transform = 'translate(-50%, -50%)';
    confirmDiv.style.backgroundColor = 'white';
    confirmDiv.style.padding = '20px';
    confirmDiv.style.border = '1px solid #ccc';
    confirmDiv.style.boxShadow = '0 0 10px rgba(0,0,0,0.2)';
    confirmDiv.style.zIndex = '9999';
    confirmDiv.style.textAlign = 'center'; // 让内容居中显示

    const messageP = document.createElement('p');
    messageP.innerHTML = message;
    // 添加样式确保换行
    messageP.style.whiteSpace = 'pre-line';
    confirmDiv.appendChild(messageP);

    const confirmButton = document.createElement('button');
    confirmButton.textContent = buttonText;
    const fuzzyXpath1=fuzzyXpath[0];
    const fuzzyXpath2=fuzzyXpath[1];
    confirmButton.addEventListener('click', () => {
      if (buttonText === "下一步") {
        const xpath = fuzzyXPath.generateSamePath(fuzzyXpath1, fuzzyXpath2);
        handlerOpenEdit(e, target, xpath, true, "", apex);
      } else {
        fuzzyStart();
      }
      document.body.removeChild(confirmDiv);
    });
    confirmDiv.appendChild(confirmButton);

    document.body.appendChild(confirmDiv);
    fuzzypause();
    _remove();
  };


  const pageSelected = async () => {
    if (isFuzzyStartSelect || isStartSelect) {
      createToast("请先关闭其他圈选功能");
      return;
    }
    // const { file, base64 } = await createSelectImage(document.body);
    const { page } = getPageInfo();
    const url = window.location.href;
    window.open(`http://ping.migu.cn/apex/#/full-collector?t=p&appId=${encodeURIComponent(apex.appId)}&page=${encodeURIComponent(page)}&u=${encodeURIComponent(url)}`, '_blank');
  };

  const selectEle = getEleByParent(".select", controlEle);
  const pageEle = getEleByParent(".page", controlEle);
  const fuzzyEle = getEleByParent(".fuzzy", controlEle);
  addListener(fuzzyEle as Element, "click", fuzzyStart);
  addListener(selectEle as Element, "click", start);
  addListener(pageEle as Element, "click", pageSelected);

  addListener(
    document.body,
    "click",
    (e: Event) => {
      if (checkIsSvg(e?.target as HTMLElement)) return;
      if (isStartSelect) {
        if (isMobileDevice()) {
          handlerMove(e);
        }
        let target = e.target as HTMLElement | null;
        target = selectTarget(target);
        if (!target) return;
        const xpath = getElementAxis(target);
        const content = getContentText(target);
        handlerOpenEdit(e, target, xpath, false, content, apex);
      } else if (isFuzzyStartSelect) {
        let target = e.target as HTMLElement | null;
        target = selectTarget(target);
        if (!target) return;
        e.preventDefault();
        e.stopPropagation();
        const xpath = getElementAxis(target);
        fuzzyXpath.push(xpath);
        if (fuzzyXpath.length === 2) {
          confirmFuzzy(e, target);
        } else {
          createToast("请继续选择第二个视图view");
        }
      }

    },
    true
  );
}
