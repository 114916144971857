let passiveSupported = false;

try {
  const options = {
    get passive() {
      // 该函数会在浏览器尝试访问 passive 值时被调用。
      passiveSupported = true;
      return false;
    },
  };
  // @ts-ignore
  window.addEventListener("test", null, options);
  // @ts-ignore
  window.removeEventListener("test", null, options);
} catch (err) {
  passiveSupported = false;
}

/**
 * 添加dom事件
 * @param element-元素
 * @param event-事件名
 * @param listener-监听器
 * @param options-选项
 */
export function addListener(
  element: Element | Document | Window,
  event: string,
  listener: EventListenerOrEventListenerObject,
  options?: boolean | AddEventListenerOptions
) {
  let opts = options;
  if (options === false || options === undefined) {
    opts = passiveSupported ? { passive: true } : false;
  }
  element.addEventListener(event, listener, opts);

  function stop() {
    element.removeEventListener(event, listener, opts);
  }
  return stop;
}
// 定义默认可点击元素的选择器
const DEFAULT_CLICKABLE_SELECTORS = [
  'a', 'button', 'input[type="submit"]',
  'input[type="radio"]', 'input[type="checkbox"]',
  'area', 'label'
];

export function selectTarget(inputTarget: HTMLElement | null): HTMLElement | null {
  if (!inputTarget) return null;
  let current = inputTarget;
  while (current && current !== document.body) {
    // 检查是否为默认可点击元素
    if (DEFAULT_CLICKABLE_SELECTORS.some(sel => current.matches(sel))) {
      return current;
    }
    /** 这里依赖方法交换, 当前库的加载时序不确定，所以采用业务方标记的方案，在代码最前面，添加
        const originalAddEventListener = Element.prototype.addEventListener;
        Element.prototype.addEventListener = function (type, listener, options) {
            if (type === 'click' && this!== document && this!== document.body) {
                this.setAttribute('has-click-listener', 'true');
            }
            return originalAddEventListener.call(this, type, listener, options);
        };
     */
    // 检查是否绑定了点击事件监听器
    if (current.hasAttribute('has-click-listener')) {
      return current;
    }
    const pa = current.parentElement;
    if (pa === null) return inputTarget;
    else {
      current = pa;
    }
  }
  // 若递归到 body 都没找到，返回 inputTarget
  return inputTarget;
}
/**
 * 获取元素坐标标记
 */
export function getElementAxis(node: Element, flag = "apex") {
  let nodeId = "";
  const path = [];

  while (node.nodeType === 1 && node.tagName.toLowerCase() !== "body") {
    const apexId = node.getAttribute(flag);
    if (apexId) {
      nodeId = apexId;
      break;
    }
    const childNodes = node.parentNode?.children || [];
    let count = 0;
    let index = 1;

    for (let i = 0; i < childNodes.length; i++) {
      const sibling = childNodes[i];
      if (sibling.nodeType === 1 && sibling.tagName === node.tagName) {
        count++;
        if (sibling === node) {
          index = count;
        }
      }
    }
    let xname = node.tagName.toLowerCase();
    xname += `[${index}]`;
    path.unshift(xname);
    node = node.parentNode as Element;
  }
  if (nodeId) {
    return nodeId;
  }
  return path.join("/");
}

/**
 * 获取元素内的所有文本内容
 * @param {Element} element - 要获取文本内容的元素
 * @returns {Array<string>} - 包含元素内所有文本的数组
 */
export function getContentText(element: Element): string {
  const textList: string[] = [];

  function collectText(node: Node): void {
    if (node.nodeType === Node.TEXT_NODE) {
      const text = node.textContent?.trim();
      if (text) {
        const processedText = text.length > 6? text.slice(0, 6) : text;
        textList.push(processedText);
      }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      for (let i = 0; i < node.childNodes.length; i++) {
        collectText(node.childNodes[i]);
      }
    }
  }

  collectText(element);

  // 截取前 2 个元素
  const limitedTextList = textList.slice(0, 2);

  const content = limitedTextList.join(', ');
  const count = limitedTextList.length;

  return `${content}`;
}


/**
 * 获取页面信息
 */
export function getPageInfo() {
  let pageUrl = window.location.href;
  const url = new URL(pageUrl);
  const { origin, hostname, pathname, hash, search } = url;
  const searchParams = new URLSearchParams(url.search);
  let cFrom = searchParams.get('cfrom');
  if (!cFrom) cFrom = "";
  let page = `${pathname}`;
  //特殊处理赤兔的链接，id是页面标识
  if (pathname.includes('app/gm')) {
    const id = searchParams.get('id');
    page = `${page}?id=${id}`;
  }
  if (hash.length > 1) {
    page = `${page}${hash}`;
  }
  
  page = page.replace(/([?&])apex_debug=1/gi, ""); // 排除圈选模式本身
  pageUrl = pageUrl.replace(/([?&])apex_debug=1/gi, "");

  return {
    pageUrl,
    page,
    cFrom,
    activityId: "",
  };
}

/**
 * 计算页面最大zIndex
 * @returns
 */
export function countMaxZIndex() {
  const elements = Array.from(document.querySelectorAll("*"));
  return elements.reduce(
    (r, e) => Math.max(r, +window.getComputedStyle(e).zIndex || 0),
    1
  );
}

/**
 * 创建DOM元素
 * @param tag
 * @param attr
 * @param content
 * @returns
 */
export function createElement(
  tag: string,
  attr: { [key: string]: string },
  content?: string
) {
  const ele = document.createElement(tag);
  Object.keys(attr).forEach((item) => {
    ele.setAttribute(item, attr[item]);
  });
  if (content) ele.innerHTML = content;
  return ele;
}

/**
 * 动态创建CSS样式表
 * @param css
 */
export function createStyle(css: string) {
  const style = createElement("style", { type: "text/css" });
  style.textContent = css;
  document.head.appendChild(style);
}
